'use strict';

const _win = $(window);
let $simpleSlider;

const simpleSlider = function () {
    let isRandomStartSlide = $("#randomic-slide-number").val();
    $simpleSlider = $('.js-herobanner-slider .slider');
    if ($simpleSlider.length) {
        $simpleSlider.each(function () {
            let $slider = $(this);
            let slidesLength = $slider.find('.gallery').length
            if ($slider.hasClass("initialized")) {
                return;
            }
            $slider.addClass("initialized");
            if (slidesLength > 1) {
                let slickParams = {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'progressive',
                    dots: false,
                    centerMode: false,
                    swipe: true,
                    arrows: true,
                    prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow slider-arrow" aria-label="Prev" role="button" style="display: block;" aria-disabled="false">  </button>',
                    nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow slider-arrow" aria-label="Next" role="button" style="display: block;" aria-disabled="false">  </button>',
                    responsive: [{
                        breakpoint: 960,
                        settings: {
                            infinite: true,
                            swipe: true,
                            arrows: false,

                        }
                    }
                    ],
                }
                if (isRandomStartSlide) {
                    slickParams.initialSlide = Math.floor(Math.random() * slidesLength);
                }
                $(this).css("visibility", "visible");
                $(this).slick(slickParams);
            }
        });
    }
    $(window).trigger('slick::initialized');
};
$(_win).on('load',function() {
    simpleSlider();
});




$(document).ready(function () {
    let isRandomStartSlide = $("#randomic-slide-number").val();


    let visualBoxSliderOptions = {
        "default": {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        centerMode: true,
                    }
                }
            ]
        }
    };

    $(".js-herobanner-sliders").each(function() {
        let $slider = $(this);
        if ($slider.hasClass("initialized")) {
            return;
        }

        let counterLi = $slider.find(".slick-dots li").length;
        let widthLi =  100 / counterLi +'%';
        $slider.find(".slick-dots li").css('width',widthLi);
        $slider.addClass("initialized");

        if ($(this).find('.js-slider').length > 1) {

            let sliderType = $(this).attr('data-slider');
            if (!sliderType) {
                sliderType = "default";
            }

            if(isRandomStartSlide){
                visualBoxSliderOptions[sliderType].initialSlide = Math.floor(Math.random() * $(this).find('.js-slider').length);
            }
            $(this).css("visibility", "visible");
            $(this).slick(visualBoxSliderOptions[sliderType]);
            handlePlyrInsideSlider($(this));
        }
    });
    simpleSlider();
});

